import { create } from 'zustand'

import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import { TlayoutProps } from '@/typings/api/layout'
import { extractFooterData } from '@/helper/layout/footer'
import { extractHeaderData } from '@/helper/layout/header'
import { devtools } from 'zustand/middleware'
import { YapiGetV1HomeWebsiteGetData } from '@/typings/yapi/HomeWebsiteGetDataV1GetApi'
import { YapiGetV1ChainStarGetNavigationListNavigationBarListData } from '@/typings/yapi/ChainStarGetNavigationV1GetApi'
import { MenuWidthEnum } from '@/constants/layout/basic-web-info'
import { ICurrency } from '@/features/layout/recreation-header/components/wallet'

type TLayoutStore = ReturnType<typeof getStore>

function getStore(set) {
  return {
    layoutProps: {} as YapiGetV1HomeWebsiteGetData | TlayoutProps | undefined,
    setLayoutProps: (layoutProps?: YapiGetV1HomeWebsiteGetData | TlayoutProps | undefined) =>
      set(() => {
        if (layoutProps) {
          return { layoutProps }
        }
        return {}
      }),
    navigationMenu: [] as YapiGetV1ChainStarGetNavigationListNavigationBarListData[],
    setNavigationMenu: (data: YapiGetV1ChainStarGetNavigationListNavigationBarListData[]) =>
      set(
        produce((draft: TLayoutStore) => {
          draft.navigationMenu = data
        })
      ),
    footerData: undefined as ReturnType<typeof extractFooterData>,
    setFooterData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.footerData = data
        })
      ),

    headerData: { businessName: '' } as ReturnType<typeof extractHeaderData>,
    setHeaderData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.headerData = data
        })
      ),
    // 通过 postmessage 获取用户余额
    postMessageAmount: '',
    setPostMessageAmount: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.postMessageAmount = data
        })
      ),
    // 用户余额信息
    balanceInfo: {
      currentCurrency: {} as ICurrency,
      raCurrency: {} as ICurrency,
    },
    setBalanceInfo: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.balanceInfo = data
        })
      ),
    columnsDataByCd: {} as Record<string, any>,
    setColumnsDataByCd: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.columnsDataByCd = data
        })
      ),
    guidePageBasicWebInfo: {} as any,
    setGuidePageBasicWebInfo: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.guidePageBasicWebInfo = data
        })
      ),
    // 是否窄屏，小于 1200 为窄屏
    narrowScreen: false,
    setNarrowScreen: (flag: boolean) =>
      set(
        produce((draft: TLayoutStore) => {
          draft.narrowScreen = flag
        })
      ),
    // 菜单宽度
    menuWidth: MenuWidthEnum.open,
    setMenuWidth: (width: number) =>
      set(
        produce((draft: TLayoutStore) => {
          draft.menuWidth = width
        })
      ),
    // 内容区域 dom，打开遮罩隐藏滚动条使用
    contentDom: null as HTMLElement | null,
    setContentDom: (dom: HTMLElement | null) =>
      set(
        produce((draft: TLayoutStore) => {
          draft.contentDom = dom
        })
      ),
    layoutLoading: true,
    setLayoutLading: (flag: boolean) => {
      set(() => {
        return {
          layoutLoading: flag,
        }
      })
    },
  }
}
const baseLayoutStore = create(devtools(getStore, { name: 'layout-store' }))

const useLayoutStore = createTrackedSelector(baseLayoutStore)

export { useLayoutStore, baseLayoutStore }
