/**
 * 业务公用枚举
 */

import { BooleanEnum } from '@/constants/base'

/**
 * 常用字符符号
 */
export enum SymbolsEnum {
  /** 小于 */
  lessThan = '<',
  /** 大于 */
  greaterThan = '>',
  /** 小于等于 */
  lessThanOrEqual = '≤',
  /** 大于等于 */
  greaterThanOrEqual = '≥',
  /** 默认占位 */
  defaultPlaceholder = '-',
  /** 默认数字占位 */
  defaultNumPlaceholder = '0',
}

/**
 * VIP 等级枚举
 */
export enum VipLevelEnum {
  lv0 = 'LV0',
  lv1 = 'LV1',
  lv2 = 'LV2',
  lv3 = 'LV3',
  lv4 = 'LV4',
  lv5 = 'LV5',
  lv6 = 'LV6',
  lv7 = 'LV7',
  lv8 = 'LV8',
  lv9 = 'LV9',
  lv10 = 'LV10',
}

/**
 * 公用启用禁用枚举
 */
export enum EnableEnum {
  /** 启用 */
  enable = 'enable',
  /** 禁用 */
  disable = 'disable',
}

/**
 * 请求缓存时间
 */
export enum RequestCacheTimeEnum {
  /** 首页游戏列表 5 分钟 */
  homeGameList = 1000 * 60 * 5,
  /** 首页顶部轮播图 5 分钟 */
  homeBanner = 1000 * 60 * 5,
}

/**
 * 请求缓存 key
 */
export enum RequestCacheKeyEnum {
  /** 首页游戏分类列表 */
  homeGameCategory = 'homeGameCategory',
  /** 首页顶部轮播图 */
  homeBanner = 'homeBanner',
}

/** 钱包模式 */
export enum WalletModeEnum {
  all = 'all', // 支持所有钱包模式
  fundTransfer = 'fund_transfer', // 转账模式
  singleWallet = 'single_wallet', // 单一钱包
}

export function GetWalletMode(char: string | undefined) {
  if (!char || char === 'undefined' || /,/.test(char)) return WalletModeEnum.all
  return char
}

/** 是否支持手机号 */
export function GetUserLoginAndRegisterMethods(char: string | undefined) {
  if (!char || char === 'undefined' || /,/.test(char)) return true
  return char === BooleanEnum.isTrue
}

/** 是否显示游戏供应商 */
export function GetUseGameSuppliersMethods(char: string | boolean | undefined) {
  if (char === undefined) return true
  return char
}

/** 侧边栏体育菜单是否跳转 */
export function GetUseSportsMenuMethods(char: string | boolean | undefined) {
  if (char === undefined) return true
  return char
}

/**
 * 判断是否需要展示相关内容
 * true/undefined：展示
 * false：不展示
 */
export function showLocaleInfoContent(char: boolean | undefined) {
  if (char || char === undefined) return true
  return false
}

/**
 * 固定折算货币
 * 为满足某些商户和场景下固定折算货币需求
 */
export enum FixedCurrencyTypeCdEnum {
  /** 巴西币 */
  BRL = 'BRL',
}

/**
 * 试玩账号头部菜单限制路径
 */
export const ToolbarTrialAccountLimitPath = [
  /** 跟单 */
  '/copy-trading',
  /** c2c 交易 */
  '/c2c/fast-trade',
  /** 邀请返佣 */
  '/agent',
  /** 代理中心 */
  '/agent/agency-center',
  /** 福利中心 */
  '/welfare-center',
]

/**
 * 收款方式
 */
export const PaymentMethod = {
  /** PIX */
  PIX: 'PIX',
}

/**
 * 公共数据字典类型
 */
export enum CommonDictionaryTypeEnum {
  /** 语言类型 */
  languageType = 'lan_type_cd',
}

/** 单法币 */
export enum virtualEnum {
  /** 虚拟 */
  virtualCurrency = 1,
  /** 单法币 */
  FiatCurrency = 2,
}

/** 本地配置币种模式枚举 */
export enum CurrencyModeErationEnum {
  /** 单币种 */
  singleCurrency = 'singleCurrency',
  /** 多币种 */
  multiCurrency = 'multiCurrency',
  /** 单法币 */
  singleFiatCurrency = 'singleFiatCurrency',
}

/** 是否外链 */
export enum IsLinkEnum {
  yes = 1,
  no = 2,
}

/**
 * 表单下拉选择项类型
 */
export enum FromFilterTypeEnum {
  /** 全部 */
  all = 0,
}
